<template>
  <b-row class="justify-content-center i-border i-outer-padding central-section i-vertical-no-photo">
    <b-col cols="12" sm="9" md="6"
           class="text-uppercase i-primary text-left text-sm-center">
      <h2>
        {{ $t('challenges.geometry.section1.header') }}
      </h2>
    </b-col>
    <div class="w-100" />
    <b-col cols="12" md="8" lg="7" class="pt-3 pb-4 pb-md-5 text-left text-sm-center">
      <div class="i-secondary i-text">
        {{ $t('challenges.geometry.section1.subheader') }}
      </div>
    </b-col>
    <div class="w-100"></div>
    <b-col v-for="(feature, index) in features"
           :key="'feature'+index"
           cols="12" md="6" lg="5">
      <feature-tile
          :src="feature.src"
          :alt="feature.alt"
      >
        <template #header>
          {{feature.title}}
        </template>
        <template #subheader>
          {{feature.text}}
        </template>
      </feature-tile>
    </b-col>
  </b-row>
</template>

<script>
import FeatureTile from "@/components/utility/FeatureTile"
export default {
  components: {
    FeatureTile
  },
  data() {
    return {
      features: [
        {
          title: this.$t('challenges.geometry.section1.features.feature1.title'),
          text: this.$t('challenges.geometry.section1.features.feature1.text'),
          alt: this.$t('challenges.geometry.section1.features.feature1.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/geometric/icons/dimensional_Ith10KG9-.svg?updatedAt=1633130465300',
        },
        {
          title: this.$t('challenges.geometry.section1.features.feature2.title'),
          text: this.$t('challenges.geometry.section1.features.feature2.text'),
          alt: this.$t('challenges.geometry.section1.features.feature2.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/geometric/icons/high_accuracy_k4FUCYEsZxD.svg?updatedAt=1633130465375',
        },
        {
          title: this.$t('challenges.geometry.section1.features.feature3.title'),
          text: this.$t('challenges.geometry.section1.features.feature3.text'),
          alt: this.$t('challenges.geometry.section1.features.feature3.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/geometric/icons/measurements_EPVNqfpyXi4.svg?updatedAt=1633130465923',
        },
        {
          title: this.$t('challenges.geometry.section1.features.feature4.title'),
          text: this.$t('challenges.geometry.section1.features.feature4.text'),
          alt: this.$t('challenges.geometry.section1.features.feature4.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/geometric/icons/logging__Cn_bRkdju.svg?updatedAt=1633130466173',
        },
        {
          title: this.$t('challenges.geometry.section1.features.feature5.title'),
          text: this.$t('challenges.geometry.section1.features.feature5.text'),
          alt: this.$t('challenges.geometry.section1.features.feature5.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/geometric/icons/monitoring_and_alerting__Ud4easGT.svg?updatedAt=1633130466252',
        },
        {
          title: this.$t('challenges.geometry.section1.features.feature6.title'),
          text: this.$t('challenges.geometry.section1.features.feature6.text'),
          alt: this.$t('challenges.geometry.section1.features.feature6.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/geometric/icons/high_speed_FA3rIRzHBwZO.svg?updatedAt=1633130465392',
        },
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.header-grey
  text-transform: none!important

.central-section
  background: rgba(100, 101, 104, 0.05)
</style>
