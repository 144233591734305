<template>
  <page-skeleton theme="light">
    <section-half-image
        arrow
        scroll-to="geometric-defects-arrow"
        hero
        photo-right-align
        hero-large-position="left"
        img-background="reverse-gradient"
        :alt="$t('challenges.geometry.images.alt1')"
        image-path="https://ik.imagekit.io/isotronic/challenges/geometric/vial-with-geometric-defects_PmHGBUwmk_QRiLNZ-vYE.png?updatedAt=1635402683900"
    >
      <template #header>
        {{$t('challenges.geometry.hero.header')}}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('challenges.geometry.hero.text') }}
        </div>
      </template>
    </section-half-image>
    <geometric-features id="geometric-defects-arrow"/>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/challenges/geometric/tube_with_defect_p_-MVD_bl_ivFnD3u8aL.jpg?updatedAt=1635402739853"
        :alt="$t('challenges.geometry.section2.alt')"
    >
      <template #header>
        {{ $t('challenges.geometry.section2.header') }}
      </template>
      <template #content>
        <div>
          {{ $t('challenges.geometry.section2.text') }}
        </div>
        <ul class="mt-3 mb-0">
          <li>
            <i18n path="challenges.geometry.section2.features.feature1.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature1.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature2.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature2.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature3.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature3.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature4.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature4.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature5.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature5.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature6.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature6.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature7.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature7.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.geometry.section2.features.feature8.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.geometry.section2.features.feature8.style') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/challenges/geometric/cameras_mirrored_rvOeemOzq_Io_JCrcggZ.png?updatedAt=1635402798506"
        :alt="$t('challenges.geometry.section3.alt')"
        photo-right-align
        background="light"
        img-background="solid"
        small-photo
    >
      <template #header>
        {{$t('challenges.geometry.section3.header')}}
      </template>
      <template #content>
        <div>
          {{ $t('challenges.geometry.section3.text') }}
        </div>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/challenges/geometric/frontal_vial_view_wywnaBfFL_EQS2DMV4lu.jpg?updatedAt=1635402834694"
        :alt="$t('challenges.geometry.images.alt2')"
    />
    <section-half-image
        image-path="Cosmetic-short.mp4"
        :alt="$t('challenges.geometry.section4.alt')"
        button-link="Cosmetic Defects"
        video
    >
      <template #header>
        {{$t('challenges.geometry.section4.header')}}
      </template>
      <template #content>
        <div>
          {{ $t('challenges.geometry.section4.text') }}
        </div>
      </template>
      <template #callout>
        {{ $t('challenges.geometry.section4.button') }}
      </template>

    </section-half-image>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";
import GeometricFeatures from "@/components/industryChallenges/geometricDefects/GeometricFeatures";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage,
    GeometricFeatures
  },
  metaInfo() {
    return {
      title: this.$t('challenges.geometry.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('challenges.geometry.meta.description')}
      ]
    }
  },
}
</script>
